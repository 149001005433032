/* __________ SEARCH AREA __________  */
#search-area {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-top: 50px;
}

/* __________ SEARCH FILTERS __________  */

#select-filters {
  position: absolute;
  top: -40px;
}

#select-filters select {
  width: 20rem;
  outline: none;
  appearance: button;
  -webkit-appearance: button;
  padding: 4px 10px;
  border: none;
  margin: 0;
  appearance: none;
  background-color: #fff;
  border-radius: 9999px;
  cursor: pointer;
  background-image: url("assets/down_arrow.svg");
  background-position: calc(100% - 14px);
  background-repeat: no-repeat;
  background-size: 9px;
}
.toggleArrow {
  height: 100%;
  width: 45%;
}

/* __________ SEARCH FORM __________  */

.search-form {
  display: inline-block;
  background-color: #fff;
  position: relative;
  text-align: center;
  width: 100%;
  height: 40px;
  border-radius: 9999px;
  z-index: 100;
}

.search-form input[type="search"] {
  background-color: transparent;
  width: calc(100% - 90px);
  position: absolute;
  top: 50%;
  left: 38px;
  transform: translate(0, -50%);
  font-size: 14px;
  color: #5a6674;
  outline: none;
  border: none;
  appearance: none;
}
.search-form button,
.show-brand {
  position: absolute;
  width: 17px;
  height: 60%;
  cursor: pointer;
  border: none;
  background: none;
  outline: none !important;
  padding: 0;
  margin: 0;
}
.search-form button[type="submit"] {
  left: 15px;
  top: 10px;
}
.search-form button.show-list,
.show-brand {
  right: 20px;
  width: 20px;
  height: 100%;
}

.search-form button[type="submit"] svg {
  width: 100%;
  height: 100%;
}

/* __________ SEARCH LIST __________  */
#search-list {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  background: #eee;
  width: 85%;
  max-height: 30vh;
  overflow: auto;
  margin: -25px auto 0px;
  padding: 18px 10px 0;
  border-radius: 4px;
  text-align: left;
  color: #000;

  transition: 0.3s ease opacity;
  z-index: 50;
}
#search-list.active {
  opacity: 1;
  pointer-events: all;
}

.search-item {
  font-size: 14px;
}
.search-item:hover {
  background-color: #fff;
  font-size: 14px;
  cursor: pointer;
}
.search-item:first-of-type {
  margin-top: 7px;
}
.search-item:last-of-type {
  margin-bottom: 5px;
}

.search-result {
  margin: 0 5px;
  vertical-align: center;
}
.search-result.manufacturer {
  display: inline-block;
  max-width: 60%;
}
.search-result.rsk {
  font-size: 0.8em;
  font-style: italic;
}
.search-result.type {
  color: #777;
  font-size: 0.8em;
  float: right;
}
.search-item:hover .search-result.name {
  color: #4aa4b6;
}
.search-item:hover .search-result.rsk {
  color: #f00;
}
.search-item:hover .search-result.type {
  color: #333;
}

/*_________New pump component__________*/

.newPump {
  z-index: 999;
  position: fixed;
  border: 6px solid #0085ad;
  border-radius: 0.25rem;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  margin-left: 20rem;
}

.imgPreviewNewPump {
  width: 100%;
}

.checkbox {
  width: 1rem;
  margin: 0;
}
.crossIcon {
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 1.5rem;
}

select {
  width: 25rem;
  padding: 0 0.3rem;
  outline: none;
  font-size: 1rem;
}

input[type="text"],
input[type="number"],
input[type="password"] {
  width: 25rem;
  padding: 0 0.3rem;
  outline: none;
  word-break: keep-all;
}
textarea {
  width: 25rem;
}

td {
  padding: 0.3rem 0.5rem;
  width: 25rem;
}

/*____________Media component_______________*/

.preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imgPreview {
  width: 30%;
}
.pageSelection {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.switchPageButton {
  margin: 0.5rem 1rem;
  padding: 1rem;
  border: none;
  background-color: transparent;
  outline: none;
  font-size: 1rem;
}
.switchPageButton:hover {
  cursor: pointer;
}

.react-pdf__Page {
  display: flex;
  justify-content: center;
}

.datasheetSelect {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.mediaInput {
  margin-bottom: 1rem;
}
.deleteFile #imgSelector,
.deleteFile #datasheetSelect,
.deleteFile #deleteAdapters,
.deleteFile #deleteComments {
  margin: 1.5rem 0;
  margin-left: 0.5rem;
}

.deleteFile {
  margin-top: 6rem;
}

.previewSection {
  display: flex;
  flex-direction: column;
}

/*_____________Modals___________*/

.ModalWrapper {
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 9999;
  margin: 0;
  top: 0;
  left: 0;
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal {
  z-index: 999;
  position: fixed;
  border: 6px solid #0085ad;
  border-radius: 0.25rem;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  margin-left: 17rem;
}

.ModalSelection h3 {
  font-size: 2rem;
  padding: 1.5rem 0 1rem 0;
}

.ModalButton {
  border-radius: 999px;
  width: 10rem;
  background-color: #0085ad;
  border: none;
  margin: 2rem 0;
  outline: none;
  color: white;
  height: 2rem;
  font-weight: 700;
}

.statusModal {
  border: 6px solid #fd151b;
  position: fixed;
  padding: 2rem 4rem;
  bottom: 5rem;
  right: 5rem;
  z-index: 9999;
  background: #eee;
  border-radius: 0.25rem;
}

/*_________ReplacementPumps component____________*/
.replacementPumps {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.replacements {
  margin: 2rem 0;
}
.replacementButtons {
  display: flex;
  justify-content: space-between;
}

/*_________Users component____________*/

.usersLi {
  padding: 0.5rem 0;
}

.usersLi button {
  margin: 0 1rem;
}

/*________Edit User component_________________*/

.userDetails {
  display: flex;
  flex-direction: column;
}
.editInput {
  margin: 0.5rem 0;
  padding: 0.2rem;
}

/*__________Edit pump component__________________*/
.editPumpButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.markets {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 25rem;
}
.marketsDiv {
  display: flex;
  align-items: center;
}
.marketsCheckbox {
  margin-left: 0.5rem;
}
.fileInput {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.inputLabel {
  position: relative;
  background-color: white;
  border: 0.1px solid rgb(133, 133, 133);
  width: 15rem;
  padding: 0.1rem 0.5rem;
  font-size: 0.9rem;
  border-radius: 2px;
  cursor: pointer;
}
.pumpImage {
  position: absolute;
  height: 5rem;
  top: -2rem;
  left: 1rem;
}

/* _______Data export component_____ */

.loading {
  display: flex;
  margin-top: 2rem;
}
.loadingText {
  margin-left: 1rem;
}

.exportDiv {
  display: flex;
  flex-direction: column;
}

/*___________ Statistics component__________________*/

.dates {
  display: flex;
  align-items: flex-end;
}
.date {
  margin-right: 2rem;
}

/*________________Buttons______________*/

.addButton,
.deleteButton,
.resetButton {
  padding: 5px 15px;
  border-radius: 99px;
  outline: none;
  border: none;
  transition: 0.1s;
  align-self: left;
  background-color: white;
  margin: 1rem 0;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  font-weight: 500;
  cursor: pointer;
}

.deleteButton {
  background-color: #c81d25;
  color: white;
}

.resetButton {
  background-color: #2d819d;
  color: white;
}

.addButton:active,
.deleteButton:active {
  transform: scale(1.03);
}
.addButton:hover {
  background-color: #4aa4b6;
  color: #fff;
}
.addButton:disabled,
.addButton:disabled:active {
  background-color: #8c8c8c;
  color: #fff;
  transform: scale(1);
}
.deleteButton:hover {
  background-color: #7d1217;
  color: #fefefe;
}
.warningSign {
  color: rgb(202, 0, 0);
  font-size: 2rem;
  margin-top: -5px;
}

button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

/*__________Save Modal________*/

.saveModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.saveButtons button {
  margin: 1rem 2rem;
}

/*_________Adapters and Comments component____________*/

#comments {
  display: -webkit-box;
  display: flex;
  align-items: center;
}

.languageInput {
  width: 5rem !important;
}

/*______________Shared classes__________________*/

.displayedNames {
  font-weight: 700;
}
.imagePreview {
  width: 20%;
  margin-top: 5rem;
}

/*___________UpdateReplacement component______*/

.scrollable {
  max-height: 90vh;
  overflow-y: scroll;
}

/* ________Accessories__________ */

.accessoriesHeaderName {
  margin-top: 2rem;
}

.accessories td {
  width: 11rem;
}
.accessories input {
  width: 10rem;
}

html {
  background: #2d819e; }


.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #2d819e;
  margin-left: 17rem; }


section {
  box-sizing: border-box;
  width: 1200px;
  min-width: min-content;
  max-width: 95vw;
  margin-top: 20px;
  background: #eee;
  border-radius: 4px;
  padding: 20px; }


.search-section {
  background: none;
  padding: 20px 0; }

.metadata {
  textarea {
    display: block;
    width: 100%;
    max-width: 100%;
    min-width: 100%; } }

.media {
  .image-preview {
    h4 {
      margin-top: -10px; }
    background: #ccc;
    border-radius: 4px;
    padding: 20px;
    box-sizing: border-box;
    margin: 20px;
    img {
      width: 20%; } } }

#search {
  #search-list {
    div:hover {
      background: #999; } } }

//
// label
//   width: 100%
//   display: inline-block

.loginWrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2d819e;
    width: 100%;
    height: 100%;
    z-index: 9999;

    .loginContainer {
      background-color: #FFFFFF;
      width: 30rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 6px;
      padding: 2rem; }

    .lostPassword {
      font-size: 0.8rem;
      cursor: pointer;
      text-decoration: underline;
      letter-spacing: 1px; }

    button {
     margin: 2rem 0;
     width: max-content;
     padding: 1rem 2rem;
     background-color: #2d819e;
     border-radius: 9999px;
     outline: none;
     color: white;
     font-size: 1rem;
     border: none;
     font-weight: 700; }

    .inputDiv {
      display: flex;
      flex-direction: column;
      margin: 0.5rem 0;

      input {
        padding: 0.5rem;
        outline: none;
        border-radius: 6px;
        border: 1px solid black; } } }

.sidenavWrapper {
    background-color: #EEEEEE;
    height: 100vh;
    width: 17rem;
    position: fixed;
    z-index: 999;
    left: 0;

    a {
      text-decoration: none; }

    ul {
      padding-left: 0; }



    .sidenavLink {
      height: 5rem;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      font-size: 1.5rem;
      &:hover {
        cursor: pointer; }
      .sidenavIcon {
        color: #8C8C8C;
        font-size: 2rem; }

      h3 {
        width: 50%;
        font-weight: 500;
        color: black;
        font-size: 1.5rem; } }

    .spanDivider {
      height: 2px;
      background-color: #cecece;
      width: 80%;
      margin: auto;
      display: block;
      border-radius: 99px; } }



ul {
   margin: 0;
   padding: 0;

   li {
     list-style: none; } }

.Wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 5rem; }






// .search-item {
//   font-size: 14px;
// }
// .search-item:hover {
//   background-color: #fff;
//   font-size: 14px;
//   cursor: pointer;
// }
// .search-item:first-of-type {
//   margin-top: 7px;
// }
// .search-item:last-of-type {
//   margin-bottom: 5px;
// }
//
// .search-result {
//   margin: 0 5px;
//   vertical-align: center;
// }
// .search-result.manufacturer {
//   display: inline-block;
//   max-width: 35%;
// }
// .search-result.rsk {
//   font-size: 0.8em;
//   font-style: italic;
// }
// .search-result.type {
//   color: #777;
//   font-size: 0.8em;
//   float: right;
// }
// .search-item:hover .search-result.name {
//   color: #4aa4b6;
// }
// .search-item:hover .search-result.rsk {
//   color: #f00;
// }
// .search-item:hover .search-result.type {
//   color: #333;
// }


.LogoutBtnWrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 1rem; }

.LogoutBtn {
  border: 1px solid #2d819e;
  border-radius: 99px;
  height: 3.5rem;
  width: 5rem;
  background-color: #2d819d;
  display: block;
  font-size: 0.7rem;
  cursor: pointer;
  color: #fefefe;

  &:hover {
    color: #fefefe; }

  span {
    display: block; }

  text {
    font-size: 0.8rem; } }

